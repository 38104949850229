import React, { Suspense } from "react"
import { Helmet } from "react-helmet"
import "react-toastify/dist/ReactToastify.css"
import { ToastContainer } from "react-toastify"
import "bootstrap/dist/css/bootstrap.min.css"

const Hero = React.lazy(() => import("../components/Hero"))

const isSSR = typeof window === "undefined"

export default () => {
  return (
    <>
      {!isSSR && (
        <Suspense fallback={<p>Cargando....</p>}>
          <Helmet>
            <title>Dinamis Trustee DT S.A.</title>
            <meta name="description" content="" />
          </Helmet>
          <Hero />
          <ToastContainer />
        </Suspense>
      )}
    </>
  )
}
